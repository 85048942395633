html,
body {
    height: 100%;
}

body::after {
  content: "";
  background: url('../img/world-map.jpg');
  background-size: cover;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

body {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

// [data-bs-theme=light] {
//     body {
//         background-color: #f5f5f5;
//     }
// }

.form-signin,
.form-guest-downloader {
    width: 100%;
    max-width: 350px;
    padding: 15px;
    margin: auto;

    .checkbox {
        font-weight: 400;
    }

    .form-floating:focus-within {
        z-index: 2;
    }

    input[type="text"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    input[type="password"] {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}
